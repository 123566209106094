<template>
  <div class="py-3 px-4">
    <v-row class="my-0">
      <v-col>
        <div class="d-flex justify-start align-start">
          <v-btn
            @click="toggleFiltersPanel"
            class="ml-auto mr-2"
            text
            depressed
            :class="[showFilter ? 'grey lighten-2' : 'white']"
          >
            <v-icon medium>
              mdi-magnify
            </v-icon>
            <span>
              Axtarış
            </span>
          </v-btn>
          <v-btn
            class="mr-0"
            color="primary"
            depressed
            @click="toggleDialog"
            :disabled="!canCreate"
          >
            <v-icon medium>
              mdi-plus
            </v-icon>
            <span>
              Əlavə et
            </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    showFiltersPanel: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showFilter: false,
    };
  },
  watch: {
    showFiltersPanel: {
      handler() {
        this.showFilter = this.showFiltersPanel;
      },
      immediate: true,
    },
  },
  methods: {
    toggleFiltersPanel() {
      this.showFilter = !this.showFilter;
      this.$emit("showFiltersPanelChanged", this.showFilter);
    },
    toggleDialog() {
      this.$emit("toggleDialog");
    },
  },
  computed: {
    canCreate() {
      return this.$store.getters.inRole("Antenna:add");
    },
  },
};
</script>
