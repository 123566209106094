<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        {{ isEditMode ? "Antenin redaktəsi" : "Yeni anten" }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createItemForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Sifarişçi"
              filled
              dense
              v-model="formData.customer"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Tezlik Operatoru"
              filled
              dense
              v-model="formData.operator"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Tezlik Ghz"
              filled
              dense
              required
              v-model="formData.frequency"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Qoşulma tipi"
              filled
              dense
              v-model="formData.connectionType"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="A-Ünvan"
              filled
              dense
              required
              v-model="formData.aAtennaORBaseStation"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="A Anten No"
              filled
              dense
              v-model="formData.aAntennaName"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="A Anten İP"
              filled
              dense
              v-model="formData.aAntennaIP"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="A Anten model"
              filled
              dense
              required
              v-model="formData.aAntennaModel"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="date"
              label="A Quraşdırılma tarixi"
              filled
              dense
              v-model="formData.aInstallationDate"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="B-Ünvan"
              filled
              dense
              required
              v-model="formData.bAtennaORBaseStation"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="B Anten No"
              filled
              dense
              v-model="formData.bAntennaName"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="B Anten İP"
              filled
              dense
              v-model="formData.bAntennaIP"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="B Anten model"
              filled
              dense
              required
              v-model="formData.bAntennaModel"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="date"
              label="B Quraşdırılma tarixi"
              filled
              dense
              v-model="formData.bInstallationDate"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-textarea
              type="text"
              label="Qeyd"
              filled
              dense
              required
              auto-grow
              rows="1"
              v-model="formData.note"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createItemForm"
        color="success"
        depressed
      >
        {{ isEditMode ? "Redaktə et" : "Yarat" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      formValidation: false,
      formData: {
        customer: null,
        frequency: null,
        connectionType: null,
        aAtennaORBaseStation: null,
        aAntennaName: null,
        aAntennaIP: null,
        aAntennaModel: null,
        aInstallationDate: null,
        bAtennaORBaseStation: null,
        bAntennaName: null,
        bAntennaIP: null,
        bAntennaModel: null,
        bInstallationDate: null,
        note: null,
      },
      formRules: {},
    };
  },
  computed: {
    isEditMode() {
      return (
        this.selectedItem && this.selectedItem.id && this.selectedItem.id > 0
      );
    },
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
          this.formData.aInstallationDate = this.convertDate(newVal.aInstallationDate);
          this.formData.bInstallationDate = this.convertDate(newVal.bInstallationDate);
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
    resetFormData() {
      this.formData = {
        customer: null,
        frequency: null,
        connectionType: null,
        aAtennaORBaseStation: null,
        aAntennaName: null,
        aAntennaIP: null,
        aAntennaModel: null,
        aInstallationDate: null,
        bAtennaORBaseStation: null,
        bAntennaName: null,
        bAntennaIP: null,
        bAntennaModel: null,
        bInstallationDate: null,
        note: null,
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
    convertDate(date) {
      if (date != null) return this.$moment(date).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>
